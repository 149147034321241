import React, {useEffect, useState} from "react";
import InputError from "../shared/InputError";
import messages from "../shared/InputErrorMessages";
import {useForm} from "react-hook-form";
import moment from "moment";
import axios from "axios";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons/faEnvelope";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useAlert} from "react-alert";

export const PlatbaDetailEmail = (props) => {
    
    const [type, setType] = useState("");
    const [loaded, setLoaded] = useState(false);
    const [cisloUctu, setCisloUctu] = useState("");
    const alert = useAlert();
    

    
    const platba = props.platba;
    
    const u1 = 'Dobrý den,' + ' \r\n\r\n' + 'evidujeme u Vás dosud neuhrazenou platbu:';

    const p1 = 'Dobrý den,' + '\r\n\r\n' + 'zasíláme podklady k platbě:';
    
    const uPredmet = "Upomínka platby VK DK";
    
    const pPredmet = "Platba VK DK";
    
    
    let sameText = "\r\n\r\nČástka: " + platba.castka + "\r\nSplatnost: " + moment(platba.splatnostDo).format('DD.MM.YYYY') + "\r\nVytvořeno: " + moment(platba.datumVytvoreni).format('DD.MM.YYYY')  + "\r\nVariabilní symbol: " + platba.variabilniSymbol + "\r\nČíslo bankovního účtu klubu: " + cisloUctu + "\r\n\r\nČlen: " + platba.clen.jmeno + " " + platba.clen.prijmeni + "\r\nČlenské ID: " + platba.clen.id ;
    sameText += platba.zacatekSezonyRok ? "\r\nZa sezónu: " + (platba.zacatekSezonyRok + "/" + (platba.zacatekSezonyRok + 1)) : null;

    sameText += "\r\n \r\nAktuální výpis Vašich plateb naleznete v intranetu v sekci Člen > Platby";

    const {register, handleSubmit, errors, reset, setValue} = useForm({
        defaultValues: {
            predmet: "",
            zprava: "",
           

        }
    });
    
    
    

    const handleUpominkaClick = () => {
        setType("Upomínka");
        setValue('predmet', uPredmet);
        setValue('zprava', (u1 + sameText));
        
    }

    const handlePlatbaClick = () => {
        setType("Platba");
        setValue('predmet', pPredmet);
        setValue('zprava', (p1 + sameText));

    }

    const onSubmit = (data, event) => {

        let formData = new FormData();
       /* data.typ = 'Finance';
        data.nazev = 'Finance';
        data.systemType = 1;
        data.selectedOptions = [];
        data.selectedOptions.push({label:platba.clen.email, value: platba.clen.id});*/

        formData.append('typ', 'Finance');
        formData.append('nazev', 'Finance');
        formData.append('systemType', '1');
        formData.append('selectedOptions', JSON.stringify([{label:platba.clen.email, value: platba.clen.id}]));
        formData.append('zprava', data.zprava);
        formData.append('predmet', data.predmet);
        
        
        

        
        axios.post('api/email/pridatEmailDoEmailFronty/', formData).then(res => {
            if (res.data.result === 1) {
                alert.success('Ok.');
                reset();
                props.hide();
            } else {
                alert.error(res.data.message, {timeout: 0});
            }
        }).catch(err => {
            alert.error("Došlo k chybě na serveru.", {timeout: 0});
            console.log(err);
        });
        


        event.preventDefault();
    }

    useEffect( ()  => {

        const loadSettings = async () => {
            await axios.get('/settings').then(res => {
                setCisloUctu(res.data.cisloUctu);
               
            });
        }

        if(!loaded) {
            loadSettings().then(() => {
                setLoaded(true);
            });
            
        }
    });
    


    


    return(
        loaded ?
      <div>
          <div className="infoBox">
              <div>
                  <button className="btn-1" onClick={handlePlatbaClick} >Platba</button>
                  <button className="btn-1" onClick={handleUpominkaClick}>Upomínka</button>

              </div>
              <div>
              <h3><FontAwesomeIcon className="i" icon={faEnvelope}/> Odeslání emailu</h3>
              </div>
         
          
          <div  style={{fontSize: 15, marginTop: 10, marginBottom: 10}}>
              <div>Odeslat jako: {type}</div>
              <div>Člen: {platba.clen.jmeno} {platba.clen.prijmeni}</div>
              <div>Email: {platba.clen.email}</div>
              <div>Starší 18: {platba.clen.starsi18 ? 'Ano' : 'Ne'}</div>
              {platba.clen.starsi18 ? null : <div>Zástupce email: {platba.clen.zastupceemail }</div>}
              <div style={{marginTop: 10}}>Číslo účtu: {cisloUctu}</div>
          </div>
          </div>
          <div className={"form"}>
              <div className={"body"}>
                  <div className={"item"}>
                      <input style={{width: 500}} placeholder="Předmět zprávy" type="text" ref={register({required: true})}  name="predmet" />
                      {errors.predmet && <InputError message={messages.required}></InputError>}
                      
                  </div>
                  <div className={"item"}>
                      <textarea style={{width: 500, height: 350}} placeholder="Zpráva" name="zprava" ref={register({required: true})} ></textarea>
                      {errors.zprava && <InputError message={messages.required}></InputError>}
                      
                    
                  </div>
              </div>
              <div className="footer">
                  <div className="ctrl">
                     
                      <div className="item">
                          <button
                              onClick={handleSubmit(onSubmit)}
                              className="btn-save"
                              
                          >
                              <FontAwesomeIcon className="i" icon={faEnvelope}/>
                              Odeslat
                          </button>
                      </div>
                    



                  </div>

              </div>
          </div>
      </div>
                : null
  )   
}